import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const ImagesContext = React.createContext({ data: {} });

function GatsbyImagesProvider({ children }: any) {
    const data = useStaticQuery(graphql`{ 
    allFile {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }`);
    const context = { data };
    return (
        <ImagesContext.Provider value={context}>
            {children}
        </ImagesContext.Provider>
    );
}

export { ImagesContext, GatsbyImagesProvider };
